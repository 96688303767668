<template>
  <div class="pa-2">

    <page-card
        cardTitle="Getting Started"
        cardSubtitle="Entering your personalisation details"
    >

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
          :disabled="readOnlyMode"
      >
      <v-card-text>
        <div  class="pa-4">
            <p>Every adventure starts with a first step, and this is your first one on the road to building your wonderful experience. The good news is that it is an easy one as well! We just need some basic information from you that will populate different parts of the booklets. The Where Did You Hear About Us and Venue questions are for our use and we would appreciate your help in providing this information.</p>
        </div>
      </v-card-text>

        <v-card elevation="2" class="pa-2 ma-2">
            <v-card-subtitle class='font-weight-black'>Step 1: Your Front Cover Text</v-card-subtitle>
            <!--<v-divider class="my-4"></v-divider>-->

          <v-card-text>

                <div class="pa-2">

                <p>
                  Naturally, it makes sense to start with the front cover. All we need here are your two first names and the location/venue you are
                  getting married at. If your wedding date has changed, please refer to the Help & FAQ section on how to change your order. You can
                  enter whatever text you wish for the location; it doesn’t necessarily have to be the name of the venue itself. So long as it fits the character
                  count, anything goes!
                </p>

                <v-text-field
                    v-model="name1"
                    :rules="nameRules"
                    :counter="20"
                    label="First Person Name"
                    @change="fieldValueChanged"
                />

                <v-text-field
                    v-model="name2"
                    :rules="nameRules"
                    :counter="20"
                    label="Second Person Name"
                    @change="fieldValueChanged"
                />

                <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        disabled
                        :value="weddingDate"
                        :rules="dateRules"
                        clearable
                        label="Date of Wedding"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date = null"
                    ></v-text-field>
                </template>
<!--                <v-date-picker-->
<!--                    v-model="weddingDate"-->
<!--                    @change="menu1 = false"-->
<!--                ></v-date-picker>-->
                </v-menu>

                <v-text-field
                    v-model="location"
                    :rules="locationRules"
                    :counter="40"
                    label="Married name to appear on front cover"
                    @change="fieldValueChanged"
                />
            </div>
          </v-card-text>

      

        </v-card>

        <v-card elevation="2" class="ma-2 pa-2">
            <v-card-subtitle class='font-weight-black'>Step 2: Helpful Information For Us</v-card-subtitle>
            <!--<v-divider class="my-4"></v-divider>-->

          <v-card-text>
            <div class="pa-2">
              <p>
                Just before you start building your experience, it would be very helpful to us if you could spare a few seconds
                to answer a couple of questions.<br>
              <br>
              Firstly, at which venue/location are you getting married?
              </p>
                <v-text-field
                    v-model="marketingWeddingVenue"
                    :counter="120"
                    label="e.g. “Chycara House”"
                    :rules="required120Rules"
                />
                <p>
                  The second question is purely for our marketing team.
                </p>
                <p>
                  Where did you hear about us?
                </p>
                <v-text-field
                    v-model="marketingReferralSrc"
                    :counter="120"
                    label="e.g. “Instagram”"
                    :rules="required120Rules"
                />
            </div>
          </v-card-text>

        </v-card>

        <div class="mt-16">
          <v-btn
              :disabled="!valid || readOnlyMode"
              type="submit"
              color="green"
              class="mr-4"
          >
            Save
          </v-btn>
          <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
          <!--      <v-btn :disabled="!valid" @click="preview">Preview</v-btn>-->
        </div>

      </v-form>

    </page-card>

  </div>
</template>

<script>
import PageCard from "@/components/PageCard";
import {form} from "@/mixins/form";

export default {
  name: "GettingStarted",
  components: {
    PageCard
  },
  mixins: [form],
  data: () => ({
    menu1: false,
    menu2: false,
    valid: true,
  }),
  computed: {
    name1: {
      get: function () {
        return this.$store.state.order.generalData.name_1
      },
      set(value) {
        this.$store.commit("setOrderGDName1", value)
      }
    },
    name2: {
      get: function () {
        return this.$store.state.order.generalData.name_2
      },
      set(value) {
        this.$store.commit("setOrderGDName2", value)
      }
    },
    weddingDate: {
      get: function () {
        return this.$store.getters.getWeddingDateFormatted
      },
      set(value) {
        this.$store.commit("setWeddingDate", value)
      }
    },
    location: {
      get: function () {
        return this.$store.state.order.generalData.wed_address
      },
      set(value) {
        this.$store.commit("setOrderGDWedAddress", value)
      }
    },
    pentext: {
      get: function () {
        return this.$store.state.order.generalData.pen_text
      },
      set(value) {
        this.$store.commit("setOrderGDPenText", value)
      }
    },
    marketingWeddingVenue: {
      get: function () {
        return this.$store.state.marketingData.weddingVenue
      },
      set(value) {
        this.$store.commit("setMarketingData", {
          weddingVenue: value
        })
      }
    },
    marketingReferralSrc: {
      get: function () {
        return this.$store.state.marketingData.referralSrc
      },
      set(value) {
        this.$store.commit("setMarketingData", {
          referralSrc: value
        })
      }
    },
  },
}
</script>
